import { Grid, GridItem } from '@chakra-ui/react';
import HomeNavbar from '../common/HomeNavbar';
import Footer from '../common/Footer';
import Activites from '../sections/activites';

import barcelonaView from "../imageComponents/TemplatesVlog/barelona_view.jpg"

const blogData = {
  "title": "Découverte de Barcelone : Un Voyage Inoubliable",
  "place": "Barcelone",
  "language": "fr-FR",
  "campagne": "barcelone_test",
  "callToAction": "Réservez maintenant",
  "heroImage": barcelonaView,
  "sections": [
    {
      "title": "Sagrada Família : Un Chef-d'Œuvre Inachevé",
      "content": "La Sagrada Família, conçue par Antoni Gaudí, est une basilique emblématique de Barcelone. Sa construction, commencée en 1882, est toujours en cours, ce qui ajoute à son mystère et à sa beauté. Les façades richement décorées et l'intérieur lumineux témoignent du génie de Gaudí. Il est recommandé de réserver les billets à l'avance pour éviter les longues files d'attente.",
      "affiliation": {
           "tour_id": "50027",     
           "url":  "https://www.getyourguide.com/sagrada-familia-l2699/sagrada-familia-billet-coupe-file-t50027"

      }
    },
    {
      "title": "Exploration du Quartier Gothique",
      "content": "Le Barri Gòtic est le cœur historique de Barcelone, avec ses ruelles étroites, ses places animées et son architecture médiévale. En vous promenant, vous découvrirez des trésors cachés, des boutiques artisanales et des vestiges romains, reflétant l'histoire riche de la ville.",
      "affiliation": {
          "tour_id": "890438",
          "url":  "https://www.getyourguide.com/barcelone-l45/barcelone-visite-du-quartier-gothique-avec-degustation-de-tapas-et-histoire-t890438"
      }
    },
    {
      "title": "Casa Batlló : L'Art de Gaudí",
      "content": "Située sur le Passeig de Gràcia, la Casa Batlló est une autre œuvre majeure de Gaudí. Sa façade colorée et ses formes organiques en font un symbole du modernisme catalan. L'intérieur est tout aussi impressionnant, avec des détails architecturaux innovants.",
      "affiliation": {
           "tour_id": "398519",     
           "url":  "https://www.getyourguide.com/barcelone-l45/barcelone-l-experience-10d-de-la-casa-batllo-t398519"         
      }
    },
    {
      "title": "Découverte de l'Aquarium de Barcelone",
      "content": "L'Aquarium de Barcelone, situé dans le Port Vell, est l'un des plus grands d'Europe. Il abrite une vaste collection de faune marine méditerranéenne et tropicale, offrant une expérience éducative et divertissante pour toute la famille.",
      "affiliation": {
            "tour_id": "61515",    
            "url":  "https://www.getyourguide.com/barcelone-l45/billet-aquarium-de-barcelone-t61515"        
      }
    },
    {
      "title": "Montjuïc : Entre Histoire et Panoramas",
      "content": "La colline de Montjuïc offre une multitude d'attractions, dont le château de Montjuïc, des jardins botaniques, le musée national d'art de Catalogne et des installations olympiques. De plus, Montjuïc offre des vues panoramiques sur la ville et la mer.",
      "affiliation": {
             "tour_id": "23477",   
             "url":  "https://www.getyourguide.com/barcelone-l45/barcelone-ticket-aller-retour-pour-le-telepherique-de-montjuic-t23477"       
      }
    },
    {
      "title": "Visite du Camp Nou",
      "content": "Stade emblématique du FC Barcelone, le Camp Nou est le plus grand stade d'Europe. Les amateurs de football peuvent visiter le musée du club et découvrir les coulisses de ce lieu mythique.",
      "affiliation": {
           "tour_id": "449261",     
           "url":  "https://www.getyourguide.com/barcelone-l45/barcelone-billet-pour-l-exposition-immersive-du-barca-t449261"         
      }

    },
    {
      "title": "Musée Picasso : Plongée dans l'Art",
      "content": "Situé dans le quartier gothique, le musée Picasso abrite une vaste collection des œuvres de l'artiste, retraçant son évolution artistique et sa relation avec Barcelone. C'est un lieu incontournable pour les amateurs d'art.",
      "affiliation": {
          "tour_id": "381019",
          "url":  "https://www.getyourguide.com/barcelone-l45/barcelone-visite-guidee-coupe-file-du-musee-picasso-t381019"
      }

    }
  ],
  "travelTips": {
    "title": "Conseils de Voyage à Barcelone",
    "sections": [
      {
        "title": "Où Loger",
        "icon": "FaHotel",
        "tips": [
          "Privilégiez le quartier de l'Eixample pour son accessibilité et son architecture.",
          "Le Barri Gòtic offre une immersion dans l'histoire avec ses ruelles pittoresques.",
          "Gràcia est idéal pour une ambiance bohème et des hébergements authentiques."
        ]
      },
      {
        "title": "Restauration",
        "icon": "FaUtensils",
        "tips": [
          "Dégustez des tapas dans les bars locaux pour une expérience culinaire authentique.",
          "Visitez le marché de la Boquería pour des produits frais et des spécialités locales.",
          "Ne manquez pas de goûter à la paella et aux fruits de mer dans les restaurants du port."
        ]
      },
      {
        "title": "Transports",
        "icon": "FaSubway",
        "tips": [
          "Utilisez le métro pour des déplacements rapides et économiques.",
          "Achetez une carte de transport pour des trajets illimités pendant votre séjour.",
          "La ville se prête bien aux balades à pied, surtout dans les quartiers centraux."
        ]
      }
    ]
  },
  "getYourGuide": {
    "title": "Explorez Barcelone avec des Activités Uniques",
    "description": "Découvrez les meilleures expériences et visites guidées à Barcelone",
    "widgetScript": "<div data-gyg-href=\"https://widget.getyourguide.com/default/activities.frame\" data-gyg-locale-code=\"fr-FR\" data-gyg-widget=\"activities\" data-gyg-number-of-items=\"4\" data-gyg-partner-id=\"4NBF07E\" data-gyg-q=\"barcelone\"><span>Powered by <a target=\"_blank\" rel=\"sponsored\" href=\"https://www.getyourguide.com/barcelona-l45/\">GetYourGuide</a></span></div>"
  },
  "conclusion": {
    "title": "Un Voyage Inoubliable",
    "content": "Barcelone est une ville qui allie histoire, culture et modernité. Chaque quartier offre une atmosphère unique, et les habitants sont chaleureux et accueillants. Ce voyage m'a laissé des souvenirs impérissables et l'envie de revenir pour découvrir encore plus de trésors cachés."
  }
}


export default function BarceloneFr() {
    return (
        <Grid
            templateAreas={
                `"header header"
        "main main"
         "footer footer"` }
            gridTemplateRows={'auto 1fr 60px'}
            gridTemplateColumns='1fr'
            h='100vh'
            overflowX="hidden"
        >

            <GridItem area={'header'}>
                <HomeNavbar />
            </GridItem>

            <GridItem area={'main'}>
                <Activites blogData={blogData}/>
            </GridItem>

            <GridItem area={'footer'}>
                <Footer />
            </GridItem>

        </Grid>
    );
};
