import { Grid, GridItem } from '@chakra-ui/react';
import HomeNavbar from '../common/HomeNavbar';
import Footer from '../common/Footer';
import Activites from '../sections/activites';

import barcelonaView from "../imageComponents/TemplatesVlog/barelona_view.jpg"
const blogData = {
  "title": "Discovering Barcelona: An Unforgettable Journey",
  "place": "Barcelona",
  "language": "en-US",
  "campagne": "barcelone_en",
  "callToAction": "Book Now !",
  "heroImage": barcelonaView,
  "sections": [
    {
      "title": "Sagrada Família: An Unfinished Masterpiece",
      "content": "The Sagrada Família, designed by Antoni Gaudí, is an iconic basilica in Barcelona. Its construction, which began in 1882, is still ongoing, adding to its mystery and beauty. The richly decorated facades and luminous interior showcase Gaudí's genius. It's recommended to book tickets in advance to avoid long queues.",
      "affiliation": {
        "tour_id": "50027",
        "url": "https://www.getyourguide.com/sagrada-familia-l2699/sagrada-familia-billet-coupe-file-t50027"
      }
    },
    {
      "title": "Exploring the Gothic Quarter",
      "content": "The Barri Gòtic is the historic heart of Barcelona, characterized by its narrow medieval streets, charming squares, and stunning Gothic architecture. As you wander through, you'll discover hidden gems, artisan shops, and remnants of Roman history, reflecting the city's rich past.",
      "affiliation": {
        "tour_id": "890438",
        "url": "https://www.getyourguide.com/barcelona-l45/barcelone-visite-du-quartier-gothique-avec-degustation-de-tapas-et-histoire-t890438"
      }
    },
    {
      "title": "Casa Batlló: Gaudí's Art",
      "content": "Located on Passeig de Gràcia, Casa Batlló is another major work by Gaudí. Its colorful facade and organic shapes make it a symbol of Catalan modernism. The interior is equally impressive, featuring innovative architectural details.",
      "affiliation": {
        "tour_id": "398519",
        "url": "https://www.getyourguide.com/barcelona-l45/barcelone-l-experience-10d-de-la-casa-batllo-t398519"
      }
    },
    {
      "title": "Discovering the Barcelona Aquarium",
      "content": "The Barcelona Aquarium, located in Port Vell, is one of the largest in Europe. It houses a vast collection of Mediterranean and tropical marine life, offering an educational and entertaining experience for the whole family.",
      "affiliation": {
        "tour_id": "61515",
        "url": "https://www.getyourguide.com/barcelona-l45/billet-aquarium-de-barcelone-t61515"
      }
    },
    {
      "title": "Montjuïc: Between History and Panoramas",
      "content": "Montjuïc hill offers a multitude of attractions, including Montjuïc Castle, botanical gardens, the National Art Museum of Catalonia, and Olympic facilities. Additionally, Montjuïc provides panoramic views of the city and the sea.",
      "affiliation": {
        "tour_id": "23477",
        "url": "https://www.getyourguide.com/barcelona-l45/barcelone-ticket-aller-retour-pour-le-telepherique-de-montjuic-t23477"
      }
    },
    {
      "title": "Visiting Camp Nou",
      "content": "The iconic stadium of FC Barcelona, Camp Nou, is the largest stadium in Europe. Football enthusiasts can visit the club's museum and explore behind the scenes of this legendary venue.",
      "affiliation": {
        "tour_id": "449261",
        "url": "https://www.getyourguide.com/barcelona-l45/barcelone-billet-pour-l-exposition-immersive-du-barca-t449261"
      }
    },
    {
      "title": "Picasso Museum: Immersion in Art",
      "content": "Located in the Gothic Quarter, the Picasso Museum houses a vast collection of the artist's works, tracing his artistic evolution and his relationship with Barcelona. It's a must-visit for art lovers.",
      "affiliation": {
        "tour_id": "381019",
        "url": "https://www.getyourguide.com/barcelone-l45/barcelone-visite-guidee-coupe-file-du-musee-picasso-t381019"
      }
    }
  ],
  "travelTips": {
    "title": "Travel Tips for Barcelona",
    "sections": [
      {
        "title": "Where to Stay",
        "icon": "FaHotel",
        "tips": [
          "Consider staying in the Eixample district for its accessibility and stunning architecture.",
          "The Gothic Quarter offers a historical ambiance with its picturesque alleys.",
          "Gràcia is ideal for a bohemian atmosphere and authentic accommodations."
        ]
      },
      {
        "title": "Dining",
        "icon": "FaUtensils",
        "tips": [
          "Savor tapas in local bars for an authentic culinary experience.",
          "Visit La Boquería market for fresh produce and local specialties.",
          "Don't miss trying paella and seafood in the port's restaurants."
        ]
      },
      {
        "title": "Transportation",
        "icon": "FaSubway",
        "tips": [
          "Utilize the metro for quick and economical travel.",
          "Purchase a transport card for unlimited journeys during your stay.",
          "The city is walkable, especially in central neighborhoods."
        ]
      }
    ]
  },
  "getYourGuide": {
    "title": "Explore Barcelona with Unique Activities",
    "description": "Discover the best experiences and guided tours in Barcelona",
    "widgetScript": "<div data-gyg-href=\"https://widget.getyourguide.com/default/activities.frame\" data-gyg-locale-code=\"en-US\" data-gyg-widget=\"activities\" data-gyg-number-of-items=\"4\" data-gyg-partner-id=\"4NBF07E\" data-gyg-cmp=\"barcelona_en\" data-gyg-q=\"barcelona\"><span>Powered by <a target=\"_blank\" rel=\"sponsored\" href=\"https://www.getyourguide.com/barcelona-l45/\">GetYourGuide</a></span></div>"
  },
  "conclusion": {
    "title": "An Unforgettable Journey",
    "content": "Barcelona is a city that blends history, culture, and modernity. Each neighborhood offers a unique atmosphere, and the locals are warm and welcoming. This trip left me with lasting memories and a desire to return to uncover more hidden treasures."
  }
}


export default function BarceloneFr() {
    return (
        <Grid
            templateAreas={
                `"header header"
        "main main"
         "footer footer"` }
            gridTemplateRows={'auto 1fr 60px'}
            gridTemplateColumns='1fr'
            h='100vh'
            overflowX="hidden"
        >

            <GridItem area={'header'}>
                <HomeNavbar />
            </GridItem>

            <GridItem area={'main'}>
                <Activites blogData={blogData}/>
            </GridItem>

            <GridItem area={'footer'}>
                <Footer />
            </GridItem>

        </Grid>
    );
};
