import React from 'react';
import {
  Container,
  Box,
  Heading,
  Text,
  Divider,
  useColorMode,
  useTheme,
  Card,
  Image,
  Flex,
  CardBody,
  VStack,
  IconButton,
} from '@chakra-ui/react';

import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { useAppContext } from '../../context/AppContext';
import ModernLoader from '../common/ModernLoader';


const TravelDaysCarousel = ({ sejour, searchResult }) => {
  const { colorMode } = useColorMode();

  return (
    <Box position="relative" maxW={{ base: "100vw", md: "800px" }} mx="auto" w="100%">
      <style>{`
    @media (min-width: 768px) {
      .swiper {
        min-width: 352px !important;
      }
    }
  `}</style>
      <style>{`
    @media (max-width: 768px) {
      .swiper-slide {
        display: flex !important;
    justify-content: center !important;
      }
    }
  `}</style>
      <Swiper
        modules={[Navigation]}
        spaceBetween={20}
        slidesPerView={1}
        navigation={{
          prevEl: '.custom-prev-button',
          nextEl: '.custom-next-button',
        }}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          }
        }}
      >
        {Object.values(sejour).map((jour, index) => (
          <SwiperSlide key={index} style={{ height: 'auto' }}>
            <Card
              overflow="hidden"
              borderRadius="xl"
              bg={colorMode === 'dark' ? 'dark_bg.darkgrey' : 'white'}
              boxShadow="xl"
              h="100%"
              w="100%"
              minW="350px"
              maxW="350px"
              display="flex"
              flexDirection="column"
              p={2}
            >
              <CardBody
                borderRadius="xl"
                flex="1"
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
              >
                <Heading
                  size="md"
                  mb={4}
                  color={colorMode === 'dark' ? 'gray.200' : 'gray.800'}
                >
                  {jour.jour}
                </Heading>
                <Divider mb={3} />
                <VStack
                  align="stretch"
                  spacing={3}
                  flex="1"
                >
                  <Box>
                    <Heading
                      size="md"
                      minH="50px"
                      mb={2}
                      color={colorMode === 'dark' ? 'gray.300' : 'gray.600'}
                    >
                      {jour.activite1.nom}
                    </Heading>
                    <Text
                      fontSize="md"
                      minH="200px"
                      whiteSpace="pre-line"
                      color={colorMode === 'dark' ? 'gray.400' : 'gray.500'}
                    >
                      {jour.activite1.description}
                    </Text>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `<div 
                                    data-gyg-href="https://widget.getyourguide.com/default/activities.frame" 
                                    data-gyg-locale-code="fr-FR"
                                    data-gyg-cmp="resultat_de_recherches" 
                                    data-gyg-widget="activities" 
                                    data-gyg-number-of-items="1" 
                                    data-gyg-partner-id="4NBF07E" 
                                    data-gyg-q="${jour.activite1.nom}">
                                    <span>Powered by 
                                      <a target="_blank" rel="sponsored" href="https://www.getyourguide.com/${searchResult.destination}-l16/">GetYourGuide</a>
                                    </span>
                                  </div>` 
                      }}
                    />
                  </Box>
                  <Box>
                    <Heading
                      size="md"
                      mb={2}
                      color={colorMode === 'dark' ? 'gray.300' : 'gray.600'}
                    >
                      {jour.activite2.nom}
                    </Heading>
                    <Text
                      minH="200px"
                      fontSize="md"
                      whiteSpace="pre-line"
                      color={colorMode === 'dark' ? 'gray.400' : 'gray.500'}
                    >
                      {jour.activite2.description}
                    </Text>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `<div 
                                    data-gyg-href="https://widget.getyourguide.com/default/activities.frame" 
                                    data-gyg-locale-code="fr-FR" 
                                    data-gyg-cmp="resultat_de_recherches" 
                                    data-gyg-widget="activities" 
                                    data-gyg-number-of-items="1" 
                                    data-gyg-partner-id="4NBF07E" 
                                    data-gyg-q="${jour.activite2.nom}">
                                    <span>Powered by 
                                      <a target="_blank" rel="sponsored" href="https://www.getyourguide.com/${searchResult.destination}-l16/">GetYourGuide</a>
                                    </span>
                                  </div>` 
                      }}
                    />
                  </Box>
                </VStack>
              </CardBody>
            </Card>
          </SwiperSlide>
        ))}
      </Swiper>
      <Flex
        position="relative"
        top={{ base: "30px", md: "40px" }}
        justifyContent="center"
        zIndex={10}
      >
        <IconButton
          className="custom-prev-button"
          icon={<FaChevronLeft />}
          variant="outline"
          colorScheme="pink"
          mr={6}
          bgColor="white"

        />
        <IconButton
          className="custom-next-button"
          icon={<FaChevronRight />}
          variant="outline"
          colorScheme="pink"
          bgColor="white"
        />
      </Flex>
    </Box >
  );
};


const TravelResult = (searchId) => {
  const { colorMode } = useColorMode();
  const theme = useTheme();
  const { state } = useAppContext();
  // Fonction pour trouver un objet par son ID
  const getSearchById = (id) => {
    return state.searches.find((search) => search.id === id);
  };

  const searchResult = getSearchById(searchId.searchId);

  const illustrationImage = {
    destination: "https://images.pexels.com/photos/1169922/pexels-photo-1169922.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    trajet: "https://images.pexels.com/photos/21014/pexels-photo.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    programme: "https://images.pexels.com/photos/4247727/pexels-photo-4247727.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    informations: "https://images.pexels.com/photos/3851254/pexels-photo-3851254.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
  };

  if (state.isLoading) {
    return (
      <Box
        position="relative"
        display="flex"
        justifyContent="center"
        alignItems="center"
        h="100vh"
        w="100vw"
        zIndex={2}
        color={colorMode === 'dark' ? theme.colors.dark.color : theme.colors.light.color}
      >
        <ModernLoader />
      </Box>
    );
  }

  if (state.error) {
    return (
      <Box
        position="relative"
        display="flex"
        justifyContent="center"
        alignItems="center"
        h="100vh"
        w="100vw"
        zIndex={2}
        color={colorMode === 'dark' ? theme.colors.dark.color : theme.colors.light.color}
      >
        <Text fontSize="2xl">Erreur: {state.error}</Text>
      </Box>
    );
  }

  if (!state.currentResult) {
    return (
      <Box
        position="relative"
        display="flex"
        justifyContent="center"
        alignItems="center"
        h="100vh"
        w="100vw"
        zIndex={2}
        color={colorMode === 'dark' ? theme.colors.dark.color : theme.colors.light.color}
      >
        <Text fontSize="2xl">Pas de résultats disponibles.</Text>
      </Box>
    );
  }

  const { presentation, trajet, sejour, informations } = state.currentResult;
  return (
    <Box
      position="relative"
      display="flex"
      flexDirection="column"
      alignItems="center"
      h="100%"
      w="100vw"
      bg={colorMode === 'dark' ? theme.colors.dark_secondary.bg : theme.colors.light_secondary.bg}
      color={colorMode === 'dark' ? theme.colors.dark_secondary.color : theme.colors.light_secondary.color}
      zIndex={2}
    >


      {/*  PRESENTATION VOYAGE  */}
      <Box
        position="relative"
        display="flex"
        flexDirection="column"
        alignItems="center"
        p={6}
        pt={{ base: 40, md: 40 }}
        pb={{ base: 10, md: 14 }}
        bg={colorMode === 'dark' ? 'dark_bg.darkgrey' : 'light_bg.lightgrey'}
        borderRadius="xl"
        w="100vw"
      >
        <Heading size="lg" mb={5} textAlign="center"
          color={colorMode === 'dark' ? 'dark_tertiary.bg' : 'light_tertiary.bg'}
        >Programmes de Voyages</Heading>
        <Divider mb={8} w="200px" />

        <Flex
          justifyContent="space-around"
          flexWrap={{ base: "wrap", md: "nowrap" }}
          alignItems="stretch"
        >
          <Card
            mb={6}
            maxW="600px"
            w={{ base: "100%", md: "calc(50% - 12px)" }}
            overflow="hidden"
            borderRadius="xl"
            mr={{ base: 0, md: 6 }}
          >
            <Image src={illustrationImage.destination} alt="Destination" objectFit="cover" h={230} />
            <CardBody p={6}>
              <Heading size="md" mb={3}>Votre destination</Heading>
              <Text whiteSpace="pre-line">
                {presentation}
              </Text>
            </CardBody>
          </Card>

          <Card
            mb={6}
            maxW="600px"
            w={{ base: "100%", md: "calc(50% - 12px)" }}
            overflow="hidden"
            borderRadius="xl"
          >
            <Image src={illustrationImage.trajet} alt="Trajet" objectFit="cover" h={230} />
            <CardBody p={6}>
              <Heading size="md" mb={3}>Trajet</Heading>
              <Text whiteSpace="pre-line">
                {trajet}
              </Text>
            </CardBody>
          </Card>
        </Flex>
      </Box>

      {/*  PROGRAMME QUOTIDIEN  */}
      <Box
        position="relative"
        display="flex"
        flexDirection="column"
        alignItems="center"
        p={6}
        pt={{ base: 6, md: 14 }}
        pb={{ base: 6, md: 14 }}
        borderRadius="xl"
        w="100vw"
      >
        <Heading size="lg" my={5} textAlign="center"
          color={colorMode === 'dark' ? 'dark_tertiary.bg' : 'light_tertiary.bg'}
        >Programme Quotidien</Heading>
        <Divider mb={8} w="200px" />

        {/*  Carousel Cards Séjour  */}
        <Box display="flex" justifyContent="center" pb={20} >
          <TravelDaysCarousel sejour={sejour} searchResult={searchResult}/>
        </Box>
      </Box>


      {/* GetYourGuide Section */}
      <Box
        position="relative"
        display="flex"
        flexDirection="column"
        alignItems="center"
        p={6}
        pt={{ base: 10, md: 14 }}
        pb={{ base: 20, md: 20 }}
        borderRadius="xl"
        w="100vw"
        bg={colorMode === 'dark' ? 'dark_bg.darkgrey' : 'light_bg.white'}

      >
        <Container maxW="container.xl">
          <VStack spacing={8} align="stretch">
            <Heading
              size="lg"
              my={5}
              textAlign="center"
              color={colorMode === 'dark' ? 'dark_tertiary.bg' : 'light_tertiary.bg'}
            >
              Activités et Expériences
            </Heading>
            <Box
              bg={colorMode === 'dark' ? 'dark_bg.black' : 'light_bg.lightgrey'}
              borderRadius="xl"
              p={6}
              boxShadow="md"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: `<div 
                              data-gyg-href="https://widget.getyourguide.com/default/activities.frame" 
                              data-gyg-locale-code="fr-FR"
                              data-gyg-cmp="resultat_de_recherches" 
                              data-gyg-widget="activities" 
                              data-gyg-number-of-items="3" 
                              data-gyg-partner-id="4NBF07E" 
                              data-gyg-q="${searchResult.destination} ${(searchResult.interests || [])[0]} ${(searchResult.interests || [])[1]}">
                              <span>Powered by 
                                <a target="_blank" rel="sponsored" href="https://www.getyourguide.com/${searchResult.destination}-l16/">GetYourGuide</a>
                              </span>
                            </div>`
                }}
              />
            </Box>
          </VStack>
        </Container>
      </Box>


      {/*  INFORMATIONS  */}
      <Box
        position="relative"
        display="flex"
        flexDirection="column"
        alignItems="center"
        p={6}
        pt={{ base: 10, md: 14 }}
        pb={{ base: 14, md: 20 }}
        mb={{ base: 16, md: 20 }}
        borderRadius="xl"
        w="100vw"
      >
        <Heading size="lg" my={5} textAlign="center"
          color={colorMode === 'dark' ? 'dark_tertiary.bg' : 'light_tertiary.bg'}
        >Informations</Heading>

        <Card mt={8} maxW="800px" w="100%" overflow="hidden" borderRadius="xl"
          bg={colorMode === 'dark' ? 'dark_bg.darkgrey' : 'white'}

        >
          <Image src={illustrationImage.informations} alt="Informations" objectFit="cover" h={230} />
          <CardBody p={6} >
            <Text whiteSpace="pre-line">
              {informations}
            </Text>
          </CardBody>
        </Card>
      </Box>


    </Box>
  );
};

export default TravelResult;
