// src/hooks/useFirestore.js
import { useState, useEffect } from 'react';
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc
} from "firebase/firestore";
import { db } from '../firebase';

const useFirestore = (collectionName) => {
  const [documents, setDocuments] = useState([]);
  const [error, setError] = useState(null); // État pour gérer les erreurs
  const collectionRef = collection(db, collectionName);

  const fetchDocuments = async () => {
    try {
      const querySnapshot = await getDocs(collectionRef);
      setDocuments(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      setError(null); // Réinitialiser l'erreur en cas de succès
    } catch (err) {
      console.error("Erreur lors de la récupération des documents:", err);
      setError(err.message);
    }
  };

  const addDocument = async (data) => {
    try {
      await addDoc(collectionRef, data);
      fetchDocuments();
      setError(null);
    } catch (err) {
      console.error("Erreur lors de l'ajout du document:", err);
      setError(err.message);
    }
  };

  const updateDocumentById = async (id, data) => {
    try {
      const docRef = doc(db, collectionName, id);
      await updateDoc(docRef, data);
      fetchDocuments();
      setError(null);
    } catch (err) {
      console.error("Erreur lors de la mise à jour du document:", err);
      setError(err.message);
    }
  };

  const deleteDocumentById = async (id) => {
    try {
      const docRef = doc(db, collectionName, id);
      await deleteDoc(docRef);
      fetchDocuments();
      setError(null);
    } catch (err) {
      console.error("Erreur lors de la suppression du document:", err);
      setError(err.message);
    }
  };

  useEffect(() => {
    fetchDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { documents, addDocument, updateDocument: updateDocumentById, deleteDocument: deleteDocumentById, error };
};

export default useFirestore;
