import { Grid, GridItem } from '@chakra-ui/react';
import HomeNavbar from '../common/HomeNavbar';
import Footer from '../common/Footer';
import Activites from '../sections/activites';

import barcelonaView from "../imageComponents/TemplatesVlog/barelona_view.jpg"
const blogData = {
  "title": "Discover Unforgettable Day Trips from Barcelona",
  "place": "Barcelona",
  "language": "en-US",
  "campagne": "barcelone_en_excursion_vente_A",
  "callToAction": "Book Now !",
  "heroImage": barcelonaView,
  "sections": [
    {
      "title": "1# Horseback Ride in Montserrat National Park",
      "content": "Imagine galloping through the mystical Montserrat mountains, letting the rhythm soothe your soul. This horseback adventure takes you to the 11th-century abbey and the revered statue of the Virgin of Montserrat, symbols of serenity. Envision the gentle landscapes, the scent of pine, and the vibrant local market filled with regional flavors. Let your imagination wander… feel the call of the ranch and nature before returning enriched and eager to relive this unique experience.<br> <b>Ready to succumb to this unforgettable adventure?</b>",
      "affiliation": {
        "tour_id": "411929",
        "url": "https://www.getyourguide.com/barcelona-l45/from-barcelona-horseback-tour-in-montserrat-national-park-t411929"
      }
    },
    {
      "title": "2# Day Tour: Andorra, France & Spain",
      "content": "Cross three countries in one day and dive into an epic journey that awakens your senses. At dawn, stroll through a medieval village, savor authentic French cuisine, and behold breathtaking Pyrenean views. Feel the thrill of receiving that coveted Andorran passport stamp and become mesmerized by hidden village charms. This multi-cultural and intensely vivid journey captivates you from the very first moment.<br><b>Craving to experience this extraordinary cross-border adventure?</b>",
      "affiliation": {
        "tour_id": "49576",
        "url": "https://www.getyourguide.com/barcelona-l45/barcelona-three-countries-in-one-day-small-group-tour-t49576"
      }
    },
    {
      "title": "#3 Girona, Figueres & the Dalí Museum",
      "content": "Step back in time as you wander Girona's medieval streets, marveling at Arab baths and a captivating Jewish quarter. Then enter Salvador Dalí’s surreal world in Figueres… Every step draws you deeper into a waking dream where art and history blend. Feel the museum’s mystical aura and let Dalí's masterpieces spark your curiosity and desire for discovery. <br><b>Can you already picture this magical day – ready to live it?</b>",
      "affiliation": {
        "tour_id": "243607",
        "url": "https://www.getyourguide.com/barcelona-l45/from-barcelona-girona-figueres-and-dali-museum-day-tour-t243607"
      }
    },
    {
      "title": "4# Montserrat Visit with Rack Railway and Black Madonna",
      "content": "Board a rack railway or cable car and watch Montserrat unveil itself like a living painting. This enchanting half-day tour leads you to a basilica where the Black Madonna inspires awe. Touch the ancient stone, taste exquisite local liqueurs, and let history wrap you in a mystical atmosphere. A spiritual and sensory experience that lingers in your mind long after you return. <br><b>Feel Montserrat’s call – let yourself be swept away now.</b>",
      "affiliation": {
        "tour_id": "1177",
        "url": "https://www.getyourguide.com/barcelona-l45/montserrat-royal-basilica-cog-wheel-train-from-barcelona-t1177"
      }
    },
    {
      "title": "5# Penedès Vineyard Tour by 4WD with Wine and Cava",
      "content": "Picture yourself traversing age-old vineyards in a 4WD, the sun warming endless vines. On this sensory excursion, taste award-winning wines and sparkling cavas paired with local cheeses and cured meats that delight your palate. Feel the passion of a winemaker sharing millennia-old secrets and let the vineyard’s magic envelop you. Every sip transports you, and the intoxicating landscape invites you to live a unique adventure in the heart of Penedès. <br><b>Can you already hear the whisper of the vines? Book your wine journey now.</b>",
      "affiliation": {
        "tour_id": "94605",
        "url": "https://www.getyourguide.com/barcelona-l45/exclusive-local-cava-and-wine-tasting-at-family-run-winery-t94605"
      }
    },
  ],
  "travelTips": {
    "title": "Travel Tips for Barcelona",
    "sections": [
      {
        "title": "Where to Stay",
        "icon": "FaHotel",
        "tips": [
          "Consider staying in the Eixample district for its accessibility and stunning architecture.",
          "The Gothic Quarter offers a historical ambiance with its picturesque alleys.",
          "Gràcia is ideal for a bohemian atmosphere and authentic accommodations."
        ]
      },
      {
        "title": "Dining",
        "icon": "FaUtensils",
        "tips": [
          "Savor tapas in local bars for an authentic culinary experience.",
          "Visit La Boquería market for fresh produce and local specialties.",
          "Don't miss trying paella and seafood in the port's restaurants."
        ]
      },
      {
        "title": "Transportation",
        "icon": "FaSubway",
        "tips": [
          "Utilize the metro for quick and economical travel.",
          "Purchase a transport card for unlimited journeys during your stay.",
          "The city is walkable, especially in central neighborhoods."
        ]
      }
    ]
  },
  "getYourGuide": {
    "title": "Explore Barcelona with Unique Activities",
    "description": "Discover the best experiences and guided tours in Barcelona",
    "widgetScript": "<div data-gyg-href=\"https://widget.getyourguide.com/default/activities.frame\" data-gyg-locale-code=\"en-US\" data-gyg-widget=\"activities\" data-gyg-number-of-items=\"4\" data-gyg-partner-id=\"4NBF07E\" data-gyg-cmp=\"barcelone_en_excursion_vente_A\" data-gyg-q=\"barcelona\"><span>Powered by <a target=\"_blank\" rel=\"sponsored\" href=\"https://www.getyourguide.com/barcelona-l45/\">GetYourGuide</a></span></div>"
  },
  "conclusion": {
    "title": "An Unforgettable Journey",
    "content": "Barcelona is a city that blends history, culture, and modernity. Each neighborhood offers a unique atmosphere, and the locals are warm and welcoming. This trip left me with lasting memories and a desire to return to uncover more hidden treasures."
  }
}


export default function BarceloneEnExcursionSA() {
    return (
        <Grid
            templateAreas={
                `"header header"
        "main main"
         "footer footer"` }
            gridTemplateRows={'auto 1fr 60px'}
            gridTemplateColumns='1fr'
            h='100vh'
            overflowX="hidden"
        >

            <GridItem area={'header'}>
                <HomeNavbar />
            </GridItem>

            <GridItem area={'main'}>
                <Activites blogData={blogData}/>
            </GridItem>

            <GridItem area={'footer'}>
                <Footer />
            </GridItem>

        </Grid>
    );
};
